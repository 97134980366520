import { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import useCountryDetail from '@/hooks/useCountryDetail';
import { successHandler, errorHandler } from '@/utils/toastHandler';
import { LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { Box } from '@/layout';
import { Paper, TextField } from '@mui/material';
import { InputWrapper, MultiInputWrapper, FormTitle } from '@/components/Container/AuthContainer/styles';
import { converToMultiLangSelectData, convertCountryCode } from '@/utils/convert';
import { title, country } from '@/data/select';
import useCurrentLang from '@/hooks/useCurrentLang';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, signIn } from '@/redux/auth/auth.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { editSubAccount, getSubAccountDetail } from '@/api/auth.api';

const MemberAccountEdit = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const stepLine = t('pages.auth.registrationStepLine', { returnObjects: true });
  const lang = useCurrentLang();
  const navigate = useNavigate();
  // 取得網址中的"userId"參數
  const { userId } = useParams();
  const [data, setData] = useState<MemberProfile>();

  const { mobileRegister, regionRegister, companyNameRegister, giveNameRegister, familyNameRegister } =
    useRegistrationValidation().companyInfo();

  // 設置表單與驗證
  const {
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    setValue,
    register,
  } = useForm<Omit<UpdateAccountForm, 'role'>>();

  const { emailRegister } = useAuthValidation();
  const {
    jobPositionRegister
  } = useRegistrationValidation().companyInfo();

  // 取得國家資訊
  const getCountry = (code: string) => {
    const defaultCountry = country['en'].filter((country: CountryPorps) => country.countryCode === code);
    return {
      acc_timezone: defaultCountry[0].timezone,
    };
  };

  const getCountryCode = (countryName: string) => {
    const defaultCountry = country['en'].filter((countryDetail: CountryPorps) => countryDetail.name === countryName);
    return {
      countryCode: defaultCountry[0].countryCode,
    };
  };

  const changeCountryName = (countryCode: string) => {
    const correctCountry = country['en'].find((item) => item.countryCode === countryCode);
    return correctCountry ? correctCountry.name : '';
  };

  // 監視country的變化, 若有新值則將country的值同樣設定到acc_MobileCountryCode
  const watchCountry = watch('acc_country');
  const watchMobileCountry = watch('acc_MobileCountryCode');

  useEffect(() => {
    if (!watchCountry) return;
    const { countryCode } = getCountryCode(watchCountry);
    setValue('acc_MobileCountryCode', convertCountryCode(countryCode));
  }, [watchCountry]);

  useEffect(() => {
    if (watchCountry && watchCountry.includes('Other')) {
      register('acc_country_other', { required: t('required.input') });
    }
  }, [watchCountry]);

  useEffect(() => {
    if (!watchMobileCountry) return;
    const newCountryName = changeCountryName(watchMobileCountry.split(' ')[0]);
    setValue('acc_country', newCountryName);
  }, [watchMobileCountry]);

  // 送出表單
  const onSubmit = handleSubmit(async (data) => {
    if(!userId) return;
    const countryCode = data.acc_MobileCountryCode.split(' ')[0];
    const { acc_timezone } = getCountry(countryCode);
    setIsLoading(true);

    try {
      const res = (await editSubAccount(userId, { ...data, acc_timezone, acc_countryCode: countryCode })).data;
      if (res.success) {
        successHandler(t('success.update'));
        navigate('/profile/addNewMembers');
      }
    } catch (error: any) {
    } finally {
      setIsLoading(false);
    }
  });

  const initData = useCallback(async () => {
    if (!userId) return;

    setIsLoading(true);
    try {
      const res = (await getSubAccountDetail(userId)).data;
      if (res.success) {
        setValue('acc_coname', res.data.acc_coname);
        setValue('acc_title', res.data.acc_title ? res.data.acc_title : '');
        setValue('email', res.data.email);
        setValue('acc_firstName', res.data.acc_firstName);
        setValue('acc_lastName', res.data.acc_lastName);
        setValue('acc_country', changeCountryName(res.data.acc_countryCode));
        setValue('acc_country_other', res.data.acc_country_other ? res.data.acc_country_other : '');
        setValue('acc_MobileCountryCode', convertCountryCode(res.data.acc_countryCode));
        setValue('acc_mobile', res.data.acc_mobile);
        setValue('acc_jobPosition', res.data.acc_jobPosition);
        setData(res.data);
      }
    } catch (error: any) {
      console.log(error);
      errorHandler(t('error.server'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 進入時回填資料
  useEffect(() => {
    if (!userId) {
      errorHandler(t('error.server'));
      return;
    }

    initData();
  }, [initData]);

  return (
    <Paper square elevation={0} style={{ maxWidth: '930px', padding: '30px 50px' }}>
      <LoadingPage isLoading={isLoading} />
      <form onSubmit={onSubmit}>
        <FormTitle>{t('companyRepresentativeInformation')}</FormTitle>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'acc_coname'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                disabled
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={companyNameRegister}
          />
        </InputWrapper>

        <MultiInputWrapper>
          <FormSelect
            control={control}
            data={converToMultiLangSelectData(title, lang)}
            inputLabel={t('form.title.label')}
            selectLabel={'acc_title'}
            name={'acc_title'}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_firstName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.giveName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={giveNameRegister}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_lastName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.familyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginLeft: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={familyNameRegister}
          />
        </MultiInputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'email'}
            defaultValue={data?.email}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={''}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                disabled
              />
            )}
            rules={emailRegister}
          />
        </InputWrapper>

        <MultiInputWrapper>
          <Controller
            control={control}
            name={'acc_jobPosition'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.jobPosition.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={jobPositionRegister}
          />

          <FormSelect
            control={control}
            data={useCountryDetail('name')}
            inputLabel={t('form.region.label')}
            selectLabel={'Region'}
            name={'acc_country'}
            register={regionRegister}
          />
        </MultiInputWrapper>

        {watchCountry && watchCountry.includes('Other') && (
          <InputWrapper>
            <Controller
              control={control}
              name={'acc_country_other'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={'form.countryOther.label'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </InputWrapper>
        )}

        <MultiInputWrapper style={{ height: 'auto' }}>
          <div style={{ width: '200px', marginRight: '10px' }}>
            <FormSelect
              control={control}
              data={useCountryDetail('countryCode')}
              inputLabel={t('form.countryCode.label')}
              selectLabel={t('form.countryCode.label')}
              name={'acc_MobileCountryCode'}
            />
          </div>
          <Controller
            control={control}
            name={'acc_mobile'}
            defaultValue={data?.acc_mobile}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={' '}
                placeholder={''}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={mobileRegister}
          />
        </MultiInputWrapper>

        <Box style={{ marginTop: 50 }}>
          <Button
            size={'lg'}
            variant={'primary'}
            rounded={false}
            marginBottom={15}
          >
            {t('buttons.save')}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default MemberAccountEdit;
