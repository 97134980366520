import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import { signOut, getAuthSelector } from '@/redux/auth/auth.slice';
import PATH from '@/router/path';

import {
  AccountVerifiedBadge,
  CreditReportBadge,
  ECertBadge,
  IdentificationCheckBadge,
  SupplementalInfoBadge,
  UserInfoBadge,
} from '../UI/Badge';
import { H4 } from '../UI/Typography/Heading';
import { SideWrapper, AccountInfo, BadgeGroup, MenuList, StarsGroup, ComapnyInfoWrapper, Hr } from './style';
import gradeGenerator from '@/utils/gradeGenerator';
import { disconnect } from '@/redux/notification/notification.slice';
import { convertLink } from '@/utils/convert';
import { getUserProgress } from '@/api/auth.api';
import useResize from '@/hooks/useResize';

const AccountSideMenu = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const { isMobile, isTablet } = useResize();
  const { userData } = useAppSelector(getAuthSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [role, setRole] = useState(1);
  const [progressStatus, setProgressStatus] = useState<BadgeGroup>({
    company: '0',
    supplemental: '0',
    person: '0',
    eCert: '0',
    creditReport: '0',
    identification: '0',
    accountVerified: '0',
  });
  const [getProgressController, setGetProgressController] = useState(false);

  const initData = useCallback(async () => {
    try {
      const res = (await getUserProgress()).data;
      if (res.success) {
        setProgressStatus(res.data);
        setGetProgressController(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const userSignout = () => {
    // 關閉所有的socket連線
    dispatch(disconnect());
    dispatch(signOut());
    // 透過windows.location.href來重新導向至首頁，避免websocket連線未關閉的問題
    window.location.href = '/';
  };

  useEffect(() => {
    if (userData) {
      setRole(userData.role);
    }
    if (getProgressController) return;
    initData();
  }, [userData, initData]);

  // 確認是否顯示徽章
  const checkShowVerifiedBadge = (data: MemberProfile) => {
    // 有子母公司的情況需子公司與母公司皆有驗證才將驗證badge顯示
    if (data.verification.accountVerified && data.childCompanies) {
      const childVerified = data.childCompanies.every((item) => item.verification.accountVerified);
      if (childVerified) {
        return true;
      }
      return false;
    }

    // 梅子公司的情況只需母公司有驗證就將驗證badge顯示
    if (data.verification.accountVerified) {
      return true;
    }
    return false;
  };

  return (
    <SideWrapper>
      <AccountInfo>
        <span className="avatar">
          <img style={{ objectFit: 'cover' }} src={userData.acc_logo ?? '/images/default_company_logo.jpg'} alt="" />
        </span>
        <BadgeGroup>
          {role === 1 && progressStatus.company && <UserInfoBadge width="28px" align="center" />}
          {!role && progressStatus.person && <UserInfoBadge width="28px" align="center" />}
          {progressStatus.identification && <IdentificationCheckBadge width="28px" align="center" />}
          {progressStatus.supplemental && <SupplementalInfoBadge width="28px" align="center" />}
          {progressStatus.eCert && <ECertBadge width="28px" align="center" />}
          {progressStatus.creditReport && <CreditReportBadge width="28px" align="center" />}
          {checkShowVerifiedBadge(userData) ? <AccountVerifiedBadge width="28px" align="center" /> : <></>}
        </BadgeGroup>

        <StarsGroup>
          {gradeGenerator(userData.stars).map((item, index) => (
            <img width="33px" key={index} src={item} alt="grade" />
          ))}
          <Link to={'/profile/comments'}>({userData.rating_count ? userData.rating_count : 0})</Link>
        </StarsGroup>

        <ComapnyInfoWrapper isTablet={isTablet}>
          <div className="col">
            <div className="title">{t('info.companyName')}</div>
            <div className="content">{userData.acc_coname || 'N/A'}</div>
          </div>

          <div className="col">
            <div className="title">{t('info.representative')}</div>
            <div className="content">
              {userData.accc_title}
              {userData.acc_firstName} {userData.acc_lastName}
            </div>
          </div>

          <div className="col">
            <div className="title">{t('info.jobPosition')}</div>
            <div className="content">{userData.acc_jobPosition || 'N/A'}</div>
          </div>

          <div className="col">
            <div className="title">{t('form.expiryDate.label')}</div>
            <div className="content">{userData.plan.expiration_date?.split(' ')[0] || 'N/A'}</div>
          </div>
        </ComapnyInfoWrapper>
      </AccountInfo>
      <MenuList>
        <Link to={PATH.profile.subscriptionDetail}>
          <H4>{t('subscriptionList')}</H4>
        </Link>
        <Link to={PATH.payment.index}>
          <H4>{t('payment')}</H4>
        </Link>
        {userData.main_account_company_info.isMainAccount && (
          <Link to={PATH.profile.addNewMembers}>
            <H4>{t('addNewMembers')}</H4>
          </Link>
        )}
        <Link to={PATH.profile.resetPassword}>
          <H4>{t('resetPassword')}</H4>
        </Link>
        <Link to={PATH.auth.signIn} onClick={userSignout}>
          <H4>{t('buttons.logout')}</H4>
        </Link>
      </MenuList>
    </SideWrapper>
  );
};

export { AccountSideMenu };
