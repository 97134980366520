import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { AccountSideMenu } from '@/components/SideMenu/AccountSideMenu';
import { ContentWrapper, MainContent, TableContainer } from './style';
import fakeData from './fakeData';
import DataTable, { TableColumn } from 'react-data-table-component';
import { getOrderList } from '@/api/payment.api';
import { Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Link } from 'react-router-dom';
import { TypeColumn } from '@/components/DataTable';
import useCurrentLang from '@/hooks/useCurrentLang';
import useUserDetail from '@/hooks/useUserDetail';

interface DataRow {
  id: number;
  product: string;
  date: string;
  amount: number;
  action: string;
}

const Payment = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const [data, setData] = useState<DataRow[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userDetail = useUserDetail({});

  const rawColumn = t('pages.payment.table.columns', { returnObjects: true });
  const columns: TableColumn<DataRow>[] = useMemo(
    () => [
      {
        name: rawColumn[0],
        selector: (row) => row.product,
        sortable: true,
      },
      {
        name: rawColumn[1],
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: rawColumn[2],
        selector: (row) => '$' + row.amount,
        sortable: true,
        cell: (row) => {
          return (
            <Flex gridGap={'5px'} alignItems={'center'}>
              {row.amount !== 0 ? (
                <>
                  <TypeColumn text={row.amount.toString()} />
                  {t('pages.payment.currency.HKD')}
                </>
              ) : (
                '-'
              )}
            </Flex>
          );
        },
      },
      {
        name: rawColumn[3],
        selector: (row) => row.action,
        cell: (row) => {
          return (
            <Link to={`/payment/${row.id}`}>
              <Button variant={'primary'} size={'table'}>
                {t('pages.payment.detail.orderDetails')}
              </Button>
            </Link>
          );
        },
      },
    ],
    []
  );

  const initData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = (await getOrderList()).data;
      const data: DataRow[] = res.data.map((item) => {
        let oldData = {} as Feature;

        if (item.details[0].feature_old_data) {
          oldData = JSON.parse(item.details[0].feature_old_data);
        }

        return {
          id: item.id,
          product:
            oldData && lang === 'en'
              ? oldData.name
              : oldData && lang === 'sc'
              ? oldData.name_cn
              : oldData && lang === 'tc'
              ? oldData.name_zh
              : item.details[0].feature_name,
          date: new Date(item.signed_date_time).toLocaleDateString(),
          amount: Number.isNaN(item.amount) ? 0 : Number(item.amount),
          action: '',
        };
      });

      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <PageContainer title={<InnerPageTitle link={'/profile/edit'} title={t('dashboard')} subtitle={t('payment')} />}>
      <LoadingPage isLoading={isLoading} />
      <ContentWrapper>
        <MainContent>
          <TableContainer>{data && <DataTable columns={columns} data={data} />}</TableContainer>
        </MainContent>
        <AccountSideMenu />
      </ContentWrapper>
    </PageContainer>
  );
};

export default Payment;
