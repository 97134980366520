import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// 手動指定 CDN 上的 worker 檔案路徑
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js`;

interface PdfReaderProps {
  pdfUrl: string;
  onAccept: () => void;
}

const PdfReader: React.FC<PdfReaderProps> = ({ pdfUrl, onAccept }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const viewerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const loadPdf = async () => {
      if (!viewerRef.current) return;
      console.log(pdfUrl);
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return;

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      viewerRef.current.appendChild(canvas);

      const renderContext = {
        canvasContext: context,
        viewport,
      };
      await page.render(renderContext).promise;

      setIsLoaded(true);
    };

    loadPdf();
  }, [pdfUrl]);

  const handleAccept = () => {
    if (isChecked) {
      onAccept();
    }
  };

  return (
    <div className="pdf-modal">
      <div className="pdf-content" ref={viewerRef}></div>
      {isLoaded && (
        <>
          <div>
            <input
              type="checkbox"
              id="accept"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <label htmlFor="accept">我已閱讀並同意條款</label>
          </div>
          <button disabled={!isChecked} onClick={handleAccept}>
            關閉
          </button>
        </>
      )}
    </div>
  );
};

export default PdfReader;
